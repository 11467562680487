<template>
  <v-container id="content">
    <RoleList />
  </v-container>
</template>

<script>
import RoleList from "@/components/roles/RoleList";
export default {
  name: "Roles",
  components: {RoleList}
}
</script>

<style scoped>
#content {
  max-width: 900px;
}
</style>